<template>
  <div class="profileMain container px-0">
    <div class="main_header">
      <div class="profile"></div>
      <div class="profile_img">
        <img :src="profileImg" alt="" id="previewImg" v-if="profileImg" />
        <img src="../assets/user_vector.png" alt="" id="previewImg" v-else />
        <input
          id="js-file-uploader"
          class="hidden"
          name="profile-picture"
          type="file"
          accept="image/*"
          @change="uploadProfile($event)"
        />
      </div>
      <span class="logoutBtn" @click="logout"
        ><b-icon icon="power"></b-icon>LOGOUT</span
      >
    </div>
    <div class="container mb-4">
      <div class="heading">
        <p class="title">{{ firstName }} {{ lastName }}</p>
      </div>
    </div>

    <b-form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-12">
          <label>
            <!-- <input type="tel" :value="form.mobile" maxlength="15" /> -->
            <div class="row">
              <div class="col-2 mt-4 pr-0">
                <VueCountryCode
                  class="countryCode"
                  :enabledCountryCode="true"
                  @onSelect="onSelect"
                >
                </VueCountryCode>
              </div>
              <div class="col-7 pl-0">
                <div class="inputField">
                  <b-icon icon="telephone"></b-icon>
                  <b-form-input
                    id="input-2"
                    type="tel"
                    v-model="form.mobile"
                    aria-describedby="input-2-live-feedback"
                    required
                  ></b-form-input>
                </div>
              </div>
            </div>
            <!-- <p>Mobile No.*</p> -->

            <b-button
              variant="outline-primary"
              type="submit"
              class="btn btn-outline-primary mobUpdateBtn"
              >Update</b-button
            >
          </label>
        </div>

        <div class="col-12 col-sm-6">
          <label>
            <input type="text" :value="form.city" disabled />
            <p>City</p>
          </label>
        </div>
        <div class="col-12 col-sm-6">
          <label>
            <input type="text" :value="form.state" disabled />
            <p>State</p>
          </label>
        </div>
        <div class="col-12 col-sm-6">
          <label>
            <input type="text" :value="form.country" disabled />
            <p>Country</p>
          </label>
        </div>
        <div class="col-12 col-sm-6">
          <label>
            <input type="text" value="123456" disabled />
            <p>Postal Code</p>
          </label>
        </div>
        <div class="col-12">
          <label>
            <input type="text" value="Lorem Ipsum" disabled />
            <p>Billing Address</p>
          </label>
        </div>
        <div class="col-12">
          <label>
            <input type="text" :value="form.address" disabled />
            <p>Address</p>
          </label>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import VueCountryCode from "vue-country-code-select";

import axios from "axios";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      form: {
        mobile: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        billingAddress: "",
        address: "",
      },
      selectedCountryCode: "",
      userId: "",
      profileImg: "",
    };
  },
  components: {
    VueCountryCode,
  },

  methods: {
    uploadProfile(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          document.getElementById("previewImg").src = reader.result;
        };
        reader.readAsDataURL(input.target.files[0]);
        let data = new FormData();
        data.append("file", input.target.files[0]);
        data.append("userId", this.userId);

        axios
          .put("https://api.vaxtraxglobal.com/api/v1/user/profile", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              role: "user",
            },
          })
          .then(({ data }) => {
            console.log(data);
            let profileData = {
              profileUrl: data.profileUrl,
            };
            this.$store.dispatch("setData", profileData);
            location.reload();
          })
          .catch((resp) => {
            console.log(resp);
          });
      }
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.selectedCountryCode = dialCode;
    },
    onSubmit() {
      var self = this;
      var storeData = self.$store.getters.getData;
      console.log(storeData.email);
      var twilioId = storeData.twilioUserId;
      var updatedNumber = self.form.mobile;
      console.log(updatedNumber);
      var dataUpdate = new FormData();
      let command = `curl "https://api.authy.com/protected/json/users/new" -H  "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi" -d user[email]=${storeData.email} -d user[cellphone]=${self.form.mobile} -d user[country_code]=${self.selectedCountryCode}`;
      dataUpdate.append("command", command);

      var config2 = {
        method: "post",
        url: "https://api.vaxtraxglobal.com/api/v1/twilio",
        data: dataUpdate,
      };

      axios(config2)
        .then(({ data }) => {
          console.log(data);
          var data2 = new FormData();
          let command2 = `curl "https://api.authy.com/protected/json/sms/${data.user.id}?force=true" -H "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi"`;

          data2.append("command", command2);
          var config2 = {
            method: "post",
            url: "https://api.vaxtraxglobal.com/api/v1/twilio",
            data: data2,
          };
          axios(config2)
            .then(({ data }) => {
              console.log(data);
              let mobileNo = this.selectedCountryCode + this.form.mobile;

              self.$router.push(`/verify/profile/${mobileNo}`);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    logout() {
      this.$store.dispatch("setAuth", false);
      this.$router.push("/login");
    },
  },

  created() {
    //  if (this.$store.getters.getAuth == false) {
    //   this.$router.push("/login");
    // }
    var storeData = this.$store.getters.getData;
    console.log(storeData);
    this.firstName = storeData.firstname;
    this.lastName = storeData.lastname;
    this.form.mobile = storeData.mobileNo;
    this.form.city = storeData.address[0].city;
    this.form.state = storeData.address[0].state;
    this.form.country = storeData.address[0].country;
    this.form.address = storeData.address[0].addr1;
    this.profileImg = storeData.profileUrl;
    this.userId = storeData.id;
  },
};
</script>

<style lang="scss" scoped>
.profileMain {
  height: 100%;
  //   min-height: calc(100vh - 145px);
  padding-bottom: 3vh;
  margin: 3vh auto;
  box-shadow: inset 12px 12px 51px #00000029, 20px 20px 20px #00000029;

  .main_header {
    position: relative;
    height: 10%;
    padding-top: 35px;

    .logoutBtn {
      position: absolute;
      bottom: 0;
      right: 10%;
      color: rgb(46, 58, 224);
      font-weight: bold;

      > .b-icon {
        transform: rotate(-90deg);
        margin-right: 5px;
        font-weight: bolder;
        color: #000;
      }

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }

      @media (max-width: 400px) {
        right: 2%;

        > .b-icon {
          margin-right: 1px;
        }
      }
    }

    .profile {
      width: 100%;
      height: 120px;
      background: #005eaa33;
      clip-path: polygon(0 0, 0 35%, 50% 100%, 100% 35%, 100% 0%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .profile_img {
      margin: auto;
      width: 120px;
      height: 120px;
      border: 5px solid #fafafa;
      border-radius: 50%;
      background-position: center;
      background-size: contain;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .hidden {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 5rem;
        max-width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .heading {
    position: relative;

    .title {
      text-align: center;
      font-weight: bolder;
    }
  }

  form {
    padding: 0 10%;

    label {
      position: relative;
      width: 100%;
      margin-bottom: 20px;

      input {
        background: transparent;
        width: 100%;
        border-radius: 5px;
        padding: 8px 15px;
        font-weight: 600;
        letter-spacing: 0.5px;
        border-color: #005eaa33;
        color: rgb(54, 54, 54);
        font-size: 0.9rem;
      }

      p {
        user-select: none;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        margin-left: 0.8rem;
        padding: 0 0.4rem;
        background: #fff;
        color: #707070;
        font-weight: 600;
        font-size: 0.8rem;
      }

      .mobUpdateBtn {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 10px;
        border-radius: 20px;
        padding: 3px 20px;
      }
    }
  }
}
</style>
